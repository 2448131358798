<template>
  <div>
    <!-- pic85366F1206FE9BEA86C402C7A412EC03.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/lu-part-2.jpg">
    <p>
      Join in the fun that LEGO Universe characters are creating now on My LEGO Network! You can connect with these funny folks as you trade, quest, collect and build!
    </p>
    <!-- pic2E2E80843AA6579346D472EF391EB8FA.jpg -->
    <img src="@/assets/news-network/mln-avatar-prof-brickkeeper.jpg" class="img-xxs r-2 leftimage">
    <p>
      <strong>Professor Brickkeeper</strong>
      is a flustered genius battling to protect the flow of fun in LEGO Universe! He can help you build a Plastic Inductor Module that’ll aid you in trading for LEGO Universe themed goodies! Or use it to show off your Creation Lab submissions on your own My LEGO Network page!
    </p>

    <!-- picF9B2E6D5DBD8E09ACF6E9D6F797AEEEB.jpg -->
    <img src="@/assets/news-network/mln-avatar-jack-knife.jpg" class="img-xxs r-2 leftimage">
    <p>
      <strong>Captain Jack Knife</strong> – He’s the wildly imaginative and blustery leader of the Black Seas Barracuda pirates! Though he drives a hard bargain, the captain just might hand over his unique Harpoon Handle if you’ll bring him some Koi Sushi! Jack says, "Yar, come share a root beer wit’ me and we shall talk o’ tradin’ for a few o’ me beauties!!"
    </p>

    <!-- pic2B6E23E7D0F3FA6821F9FA60B82F4D3A.jpg -->
    <img src="@/assets/news-network/mln-avatar-numb-chuck.jpg" class="img-xxs r-2 leftimage">
    <p>
      <strong>Numb Chuck</strong> - Leader of a ninja clan, this martial warrior is reclusive, cunning and sleepy! Help Numb Chuck build a Sparbot to stay awake and he’ll help you by chanting the Endless Ohm. In the known universe, Numb Chuck is the only living master of the Ohm’s snoring patterns!
    </p>

    <!-- picD17EE9E40535396E353CDEACB0E044AA.jpg -->
    <img src="@/assets/news-network/mln-avatar-friendly-felix.jpg" class="img-xxs r-2 leftimage">
    <p>
      <strong>Friendly Felix</strong> – Friendly Felix is a roller skating bard and lyrical poet who has lost his tune! He says, "Would you be a friend and help fix my wonky sound? Then I could return to rockin’ like the best one man band around!" If you’re brave enough to help Friendly Felix reharmonize, he’ll thank you with a musical game maker!
    </p>
    <p>
      More LEGO Universe characters will be lighting up My LEGO Network soon! Befriend each one to get in on all the creative action!
    </p>
  </div>
</template>
